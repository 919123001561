<template>
  <v-app-bar color="navbar" app>
    <v-app-bar-nav-icon @click.stop="$emit('hide', !show)"></v-app-bar-nav-icon>
    <!-- <v-toolbar-title>{{ title || 'Dashboard' }}</v-toolbar-title> -->
    <v-spacer></v-spacer>
    <div class="px-1">
      <v-btn icon color="primary">
        <v-icon>info</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text color v-on="on">
            <flag :iso="currentFlag" v-bind:squared="false" />
            <v-icon right color="primary">mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(entry, index) in languagess"
            :key="`${index}010`"
            @click="changeLocale(entry)"
          >
            <v-list-item-title>
              <flag :iso="entry.flag" v-bind:squared="false" />
              &nbsp;{{ entry.title }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
        v-if="currentUser"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <div>
              <v-badge
                color="red"
                :content="notifications"
                bordered
                overlap
                left
                v-if="notifications"
              >
                <v-icon color="primary">
                  mdi-bell
                </v-icon>
              </v-badge>
              <v-icon color="primary" v-else>
                mdi-bell
              </v-icon>
            </div>
          </v-btn>
        </template>

        <v-card class="mx-auto" max-width="500">
          <v-list two-line>
            <v-list-item-group v-model="selected" active-class="primary--text">
              <template v-for="(item, index) in chats">
                <v-skeleton-loader
                  :key="index"
                  type="list-item-avatar"
                  v-if="!loadMessages"
                ></v-skeleton-loader>
                <v-list-item
                  :key="item.name"
                  v-else
                  to="/root/support"
                >
                  <!-- <template> -->

                  <template v-slot:default="{ active }">
                    <v-list-item-avatar>
                      <v-badge
                        bordered
                        bottom
                        color="primary"
                        dot
                        offset-x="15"
                        offset-y="11"
                      >
                        <v-avatar size="35" v-if="item.messages[0].author">
                          <v-img
                            :src="
                              item.messages[0].author.photo
                                ? `${apiUrl}/images/user/${item.messages[0].author.photo}`
                                : '/profile.png'
                            "
                          ></v-img>
                        </v-avatar>
                        <v-avatar size="35" v-else>
                          <v-img
                            :src="
                              item.messages[0].to.photo
                                ? `${apiUrl}/images/user/${item.messages[0].to.photo}`
                                : '/profile.png'
                            "
                          ></v-img>
                        </v-avatar>
                      </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          item.messages[0].author
                            ? getUserName(item.messages[0].author.name)
                            : getUserName(item.messages[0].to.name)
                        "
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="item.messages[item.messages.length - 1].body"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-badge
                        color="red"
                        :content="
                          countReads(item.messages)
                            ? countReads(item.messages)
                            : 0
                        "
                        bordered
                        overlap
                        v-if="countReads(item.messages)"
                      >
                        <v-icon v-if="!active" color="grey">
                          mdi-chat
                        </v-icon>

                        <v-icon v-else color="grey">
                          mdi-chat
                        </v-icon>
                      </v-badge>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider
                  v-if="index < chats.length - 1"
                  :key="index"
                ></v-divider>
              </template>
              <v-divider></v-divider>
              <v-list-item to="/root/support">
                <v-list-item-content>
                  <v-btn block color="primary" outlined>
                    Ver todas notificações
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
        v-if="currentUser"
      >
        <template v-slot:activator="{ on }">
          <v-btn icon small v-on="on" color="primary">
            <v-avatar size="22" item>
              <img
                v-if="currentUser.photo"
                :src="`${apiUrl}/images/user/${currentUser.photo}`"
              />
              <v-icon v-else class="primary--text">mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <img
                  :src="
                    currentUser.photo
                      ? `${apiUrl}/images/user/${currentUser.photo}`
                      : `/avatar.png`
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ currentUser.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="caption">
                  {{ currentUser.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item router to="/profile/user">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-account-circle</v-icon>
              </v-list-item-action>
              <v-list-item-title>Perfil</v-list-item-title>
            </v-list-item>
            <v-list-item @click.prevent="logout">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-title>Terminar sessão</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn icon>
            <v-icon v-on="on" color="primary">settings</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-for="(setting, i) in settings"
            :key="i"
            route
            :to="setting.route"
            :exact="setting.exact"
          >
            <v-list-item-action>
              <v-icon small>{{ setting.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ setting.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { API_URL } from "@/api";
import { GC_AUTH_TOKEN, GC_USER_ID } from "@/constants/settings";
import { USER_QUERY } from "./../../auth/graphql/user";
import { mapActions, mapGetters } from "vuex";
import {
  MESSAGE_SENT_SUBSCRIPTION,
  UPDATED_COUNT_CHAT,
} from "./../modules/support/graphql/subscription";
import { CHATS_ROOM_QUERY } from "./../modules/support/graphql/Query";
import { SEND_STATUS } from "./../graphql/Query.resolver";
export default {
  name: "AppToolbar",
  props: {
    show: Boolean,
  },
  model: {
    prop: "show",
    event: "hide",
  },
  data: () => ({
    apiUrl: API_URL,
    currentFlag: "pt",
    showLogoutDialog: false,
    user: null,
    notifications: 0,
    chats: [],
    selected: [2],
    loadMessages: false,
    flag: 2,
  }),
  apollo: {
    user: {
      query: USER_QUERY,
      fetchPolicy: "no-cache",
    },
    recivedChats: {
      query: CHATS_ROOM_QUERY,
      fetchPolicy: "no-cache",
      result({ data }) {
        if (data.recivedChats) {
          this.loadMessages = true;
          console.log("recivedChats toolBar", data.recivedChats);
          this.chats = data.recivedChats;
          this.chats.forEach((element) => {
            this.notifications += this.countReads(element.messages);
          });
        }
      },
    },
    $subscribe: {
      message: {
        query: MESSAGE_SENT_SUBSCRIPTION,
        result({ data }) {
          console.log("nootifique=>>>>>", data.addChat);
          if (data.addChat.flag === "Suport") {
            this.getMessage();
          }
        },
      },
      updateCountChat: {
        query: UPDATED_COUNT_CHAT,
        result({ data }) {
          var index = this.chats.findIndex(
            (u) => u.room == data.updateCountChat.room
          );
          if (index > -1) {
            this.chats[index].messages = data.updateCountChat.messages;
            this.notifications = 0;
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
          }
        },
      },
    },
  },
  watch: {
    user: function(val) {
      this.setUserState(val);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    languagess: function() {
      return [
        { flag: "gb", language: "en", title: "English" },
        { flag: "fr", language: "fr", title: "Français" },
        { flag: "pt", language: "pt", title: "Português" },
      ];
    },
    settings: function() {
      return [
        {
          icon: "mdi-key-variant",
          title: "Nível de Acesso",
          route: "/root/root/roles",
          exact: true,
        },
        {
          icon: "mdi-account-group",
          title: "Usuários",
          route: "/root/root/users",
          exact: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      setUserState: "auth/setUser",
    }),
    changeLocale(locale) {
      this.$i18n.locale = locale.language;
      this.currentFlag = locale.flag;
    },
    logout() {
      this.logoutPropreties();
      this.leaving();
    },
    logoutPropreties() {
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_AUTH_TOKEN);
      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
      this.$router.push("/login");
    },
    countReads(messages) {
      // console.log(messages);
      if (messages[0].author) {
        return messages.filter(
          (m) => m.read === "1" && m.author.id != this.currentUser.id
        ).length;
      } else {
        return messages.filter(
          (m) => m.read === "1" && m.to.id === this.currentUser.id
        ).length;
      }
    },

    initSuport() {
      this.$router.push("/root/support/chat");
    },
    initSuportChat(valor) {
      this.$emit("initChatSuport", valor);
    },
    async leaving() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Offline",
        },
      });
    },
    async backOnline() {
      await this.$apollo.mutate({
        mutation: SEND_STATUS,
        variables: {
          user_id: this.currentUser.id,
          description: "Online",
        },
      });
    },
    getUserName(name) {
      name = name.split(" ");
      return name[0];
    },

    async getMessage() {
      await this.$apollo
        .query({
          query: CHATS_ROOM_QUERY,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          if (res.data.recivedChats) {
            this.chats = res.data.recivedChats;
            this.notifications = 0;
            this.chats.forEach((element) => {
              this.notifications += this.countReads(element.messages);
            });
            console.log("ordered", res.data.recivedChats);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    window.addEventListener("beforeunload", this.leaving);
    this.$i18n.locale = "pt";
    this.currentFlag = "pt";
  },
  mounted() {
    setTimeout(() => {
      this.backOnline();
    }, 1000);
  },
};
</script>
