import gql from 'graphql-tag'
import chatFragment from './ChatFragment'

export const UPDATED_COUNT_CHAT = gql `
subscription updateCountChat{
  updateCountChat{
    room :name
    flag
    messages{
      body
      time
      read
      date
      flag_to
      to{
      id
      name
      photo
    }
    author {
      id
      name
      photo
    }
    }
  }
}
`
export const MESSAGE_SENT_SUBSCRIPTION = gql `
  subscription addChat {
    addChat{
        ...${chatFragment}
    }
  }
`