import gql from 'graphql-tag'

export default gql `{
  name
  flag
  messages{
    body
    date
    time
    read
    flag_to
    author{
      id
      name
      photo
    }
    to{
      id
      name
      photo
    }
  }
}`