<template>
  <v-navigation-drawer
    v-model="value"
    @input="$emit('input', $event)"
    :mini-variant.sync="value"
    color="secondary"
    dark
    app
    permanent
  >
    <v-list-item class="px-2 py-1 secondary" dark>
      <v-list-item class="px-2 py-1 secondary" dark>
      <v-list-item-avatar tile size="30">
        <v-img src="/K_LOGO.png" size="50"  contain></v-img>
      </v-list-item-avatar>
      <v-list-item-title class="title font-weight-black" ><v-img src="/KENMARE_LOGO.png" max-width="120" contain class="py-1 mt-1"></v-img></v-list-item-title>
    </v-list-item>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense class="pt-0" nav>
      <template v-for="item in items">
        <v-list-group
          v-if="item.childrens"
          :prepend-icon="item.icon"
          :key="item.title"
          no-action
          active-class="primary"
          class="white--text "
          color="#fff"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <template v-for="child in item.childrens">
            <v-list-group
              v-if="child.childrens"
              :key="child.title"
              no-action
              sub-group
              active-class="primary"
              class="white--text "
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(c, i) in child.childrens"
                :key="i"
                :to="c.url"
                :exact="true"
                link
                active-class="primary"
                class="white--text"
              >
                <v-list-item-title v-text="c.title"></v-list-item-title>

                <!-- <v-list-item-icon>
                  <v-icon v-text="icon"></v-icon>
                </v-list-item-icon> -->
              </v-list-item>
            </v-list-group>

            <v-list-item
              v-else
              :key="child.title"
              :to="child.url"
              :exact="item.exact"
              router
              active-class="primary"
              class="px-3"
              style="width: 95%; margin-left:5%"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon class="white--text" v-text="child.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item>
              <!-- <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon> -->

              <!-- <v-list-item-content> -->
              <!-- <v-list-item-title v-text="child.title"></v-list-item-title> -->
              <!-- </v-list-item-content> -->
            </v-list-item>
          </template>
        </v-list-group>

        <v-list-item
          v-else
          :key="item.title"
          :to="item.url"
          :exact="item.exact"
          link
          active-class="primary"
          class="white--text"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-group
        :prepend-icon="'mdi mdi-responsive'"
        active-class="primary"
        class="white--text "
        color="#fff"
      >
        <template v-slot:activator>
          <v-list-item-title class="white--text" router active-class="primary">
            {{ "Web Site" }}
          </v-list-item-title>
        </template>
        <v-list-item
          router
          to="/page/home"
          active-class="primary"
           style="width: 95%; margin-left:5%"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon
                class="white--text"
                v-text="'mdi-subdirectory-arrow-right'"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="white--text"
              v-text="'Home'"
            ></v-list-item-title>
          </v-list-item>
        </v-list-item>
        <v-list-item
          router
          to="/page/recruiter"
          active-class="primary"
          style="width: 95%; margin-left:5%"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon
                class="white--text"
                v-text="'mdi-subdirectory-arrow-right'"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="white--text"
              v-text="'Recrutador'"
            ></v-list-item-title>
          </v-list-item>
        </v-list-item>
        <v-list-item
          router
          to="/page/candidate"
          active-class="primary"
           style="width: 95%; margin-left:5%"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon
                class="white--text"
                v-text="'mdi-subdirectory-arrow-right'"
              ></v-icon>
            </v-list-item-icon>
            <v-list-item-title
              class="white--text"
              v-text="'Candidato'"
            ></v-list-item-title>
          </v-list-item>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppMenu",
  props: {
    value: Boolean,
  },
  data: () => ({
    items: [
      {
        title: "Página Principal",
        icon: "mdi-home",
        url: "/root",
        exact: true,
      },
      {
        title: "Departamentos",
        icon: "mdi-account-tie-outline",
        url: "/root/entities",
        exact: true,
      },
      {
        title: "Candidatos",
        icon: "mdi-account-multiple",
        url: "/root/candidates",
        exact: true,
      },
      { title: "Suporte", icon: "mdi-face-agent", url: "/root/support" },
      {
        title: "Parceiro",
        icon: "mdi-account-network",
        url: "/root/partners",
        exact: true,
      },
      {
        title: "Biblioteca de Testes",
        icon: "mdi-clipboard-list",
        route: "/tests",
        childrens: [
          {
            title: "Testes",
            url: "/root/tests",
            icon: "mdi-clipboard-list",
          },
          {
            title: "Actividades",
            url: "/root/test/activities",
            icon: "mdi-trending-down",
          },
        ],
      },
    ],
    mini: false,
  }),
};
</script>
