<template>
  <div>
    <app-menu v-model="drawer" />
    <app-toolbar v-model="drawer" @initChatSuport="initChatSuport" />
    <chatChatSuport
      v-if="showChatSuporte"
      @initChatSuport="initChatSuport"
      @close="close"
      :paramMessage="paramMessage"
    />
   <v-row class="px-6 py-6">
        <v-col>
          <slot />
        </v-col>
      </v-row>
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu.vue";
import AppToolbar from "../components/AppToolbar.vue";
import chatChatSuport from "./../modules/chat/components/suport/chatMessage";
export default {
  name: "AppLayout",
  components: {
    AppMenu,
    AppToolbar,
    chatChatSuport,
    
  },
  data: () => ({
    drawer: true,
    showChatSuporte: false,
    paramMessage: [],
  }),
  methods: {
    close() {
      this.showChatSuporte = false;
    },

    initChatSuport(valor) {
      this.paramMessage = valor;
      this.showChatSuporte = !this.showChatSuporte;
    },
  },
};
</script>
